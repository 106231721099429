import React, { useState } from "react";
import TabNavItem from "../TabNavItem";
import TabContent from "../TabContent";
import WS1 from "./WS1";
import WS2 from "./WS2";

function WSTabs() {
    const [activeTab, setActiveTab] = useState("tab1");
 
    return (
      <div className="Tabs px-1">
        <ul className="nav">
          <TabNavItem  title="1" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
          <TabNavItem  title="2" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
        </ul>
   
        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
              <WS1 />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
              <WS2 />
          </TabContent>
        </div>
        <ul className="nav mb-5">
          <TabNavItem  title="1" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
          <TabNavItem  title="2" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
        </ul>
   
      </div>
    );
  };
   

export default WSTabs