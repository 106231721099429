import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function WS2() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
  return (
    <div>
    <section>
    <div className="row mt-3">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded h-50" src="images/thumbnail/Cartigo.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">Cartigo</h4><h5 className="card-title text-dark">Responsive React JS shopping cart Template</h5>
                            <p className="cars-text">A Shopping Cart Template designed in React JS is user friendly and responsive web template. This web template is designed and developed for business who want to set up their online shop by creating a full fledged shopping cart website, or for any one who want to get this template integrated within his/her website if he/she want to provide online shopping feature in their website. The shopping cart template showcase varied range of features needed for running a full fledged shopping card wsbsite or any online shop.</p>
                        </div>   
                    </article>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                    <embed className="rounded h-50" src="images/thumbnail/SimpleDesign.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">Smart Design</h4><h5 className="card-title text-dark">Basic React JS website Template</h5>
                            <p className="cars-text">This ia a basic fully responsive React JS website template. This template cater all the basic feature a standerd business website need for showcasing it's online presence. You can get this template custimized as per you requirments. Even though this is a basic web template you can avail complex functionalities and features within this web template.</p>
                        </div>
                    </article>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-sm-6 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                    <embed className="rounded h-50" src="images/thumbnail/MuscleMatrix.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">MuscleMatrix 
                            </h4><h5 className="card-title text-dark">Basic one page HTML Template</h5>
                            <p className="cars-text">Our basic responsive multipurpose template has a creative and bright design. Regardless of what topic you prefer, you won't be disappointed with the look of this template. Also, you can avail variety of awesome features that will make your website feel more user-friendly.</p>
                        </div>
                    </article>
                </div>
                <div className="col-lg-6 col-sm-6 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                    </article>
                </div>
            </div>
    </section>
</div>
  )
}

export default WS2