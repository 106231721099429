import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
//import LiveDemo from "./LiveDemo";
//import Nav from 'react-bootstrap/Nav';
//import {Routes, NavLink, Route} from "react-router-dom";

function WS1() {
  
    const navigate = useNavigate();

    const handleClick = (demoComponentName) => {
        navigate(`/live-demo/${demoComponentName}`);
      };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
  return (
    <div>
        <section>
            <div className="row mt-3">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded bg-white" src="images/thumbnail/TrivalIndia.png" alt="post-thumb" />
                        <div className="card-body">
                            <label className="card-title fs-4 fw-bold text-dark">TrivalIndia 
                                {/*<Nav>
                                    <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/LiveDemo"><b>Live Demo</b></NavLink>
                                </Nav>
                                <div className="  rounded">
                                    <Routes>
                                        <Route  path="/livedemo" element={ <LiveDemo />} />
                                    </Routes>
                                </div>*/}
                                <a className="text-dark text-uppercase font-rounded btn btn-primary rounded-4 hover-shadow shadow-lg fs-6 xbootstrap" href={"images/HTempates/TravelIndia/Home.html"} target = "_blank" rel="noreferrer">Live Demo</a>
                                <button onClick={() => handleClick('HTempates/TravelIndia/Home.html')}>
                                    Live Demo
                                </button>
                            </label>
                            <h5 className="card-title text-dark">Stunning Responsive Multipurpose Website Template</h5>
                            <p className="cars-text">Responsive multipurpose website template has a variety of pages that can include any necessary information. It can tell more about your team, services, help you write about the news & updates etc. All pages of TrivalIndia are highly responsive. It is possible due to the Bootstrap 5 framework that serves as a basis for all pages.</p>
                        </div>
                    </article>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded h-50" src="images/thumbnail/ZenithZone.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">ZenithZone 
                            </h4><h5 className="card-title text-dark">Responsive Multipurpose HTML one page Template</h5>
                            <p className="cars-text">Our new responsive multipurpose template has a creative and bright design. Regardless of what topic you prefer, you won't be disappointed with the look of this theme. Also, there you get variety of awesome features that make your website more user-friendly.</p>
                        </div>
                    </article>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded h-50" src="images/thumbnail/FSS.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">FSS</h4>
                            <h5 className="card-title text-dark">Responsive MERN Stack Web Template</h5>
                            <p className="cars-text">Responsive MERN Stack web Template specially designed to suite for multiple web apps. with some modifiations if requred. The FSS web Template is designed in MERN Stack were M stands for Manngo DB, E stands for Express JS, R stands for React JS, N stands for Node JS. This web app. is a basic layout which can be further developed in a full fledged web app. including all the features refresh customized as per client amd his business requirmen. </p>
                        </div>
                    </article>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 px-3" data-aos="fade-down">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded h-50" src="images/thumbnail/FTBEE.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">FTBEE
                            </h4><h5 className="card-title text-dark">Responsive React JS website Template with admin panel</h5>
                            <p className="cars-text">This website Template is designed to cater varied business needs. This is a multipurpose website Template with multiple pages to shocase your business information and markert your business online. Front-End of this website Template is designed  in React Js, where as backend is handled using google firebase but can easily be converted in MERN stack web application. User friendly design easy to handel web application. This is a website that comes with an admin panel that helps admin handle data visible on the website.</p>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    </div>
  )
}

export default WS1